$( document ).ready(function() {
    _accessibilitytools();
});
const _accessibilitytools = () => {
    if ( $('.quickexit').length > 0 ) {
        $('.accessibilitytools').addClass('has-quickexit');
    }
    if ( $('.accessibilitytools').length > 0 ) {
        $('.accessibilitytools-toggle').on('click enter', function(e) {
            e.preventDefault();
            $('.accessibilitytools').toggleClass('accessibilitytools--open');
        });

        const increaseBtn = $('.accessibilitytools .js--fontsize-increase');
        const decreaseBtn = $('.accessibilitytools .js--fontsize-decrease');
        const grayscaleBtn = $('.accessibilitytools .js--grayscale');
        const linksUnderlineBtn = $('.accessibilitytools .js--links-underline');
        const readableFontBtn = $('.accessibilitytools .js--readable-font');
        const resetSizeBtn = $('.accessibilitytools .js--reset-tool');
        increaseBtn.on('click', function (e) {
            e.preventDefault();
            const currentFontSizeInNumber = ($('html').css('font-size')).slice(0, -2);
            if (currentFontSizeInNumber < 23) {
                $('html').css({ 'font-size': `${+currentFontSizeInNumber+1}px` });
            }
        });
        decreaseBtn.on('click', function (e) {
            e.preventDefault();
            const currentFontSizeInNumber = ($('html').css('font-size')).slice(0, -2);
            if (currentFontSizeInNumber > 14) {
                $('html').css({ 'font-size': `${+currentFontSizeInNumber-1}px` });
            }
        });
        grayscaleBtn.on('click', function (e) {
            e.preventDefault();
            $('html').toggleClass('sr-grayscale');
        });
        linksUnderlineBtn.on('click', function (e) {
            e.preventDefault();
            $('html').toggleClass('sr-links-underline');
        });
        readableFontBtn.on('click', function (e) {
            e.preventDefault();
            $('html').toggleClass('sr-readable-font');
        });
        resetSizeBtn.on('click', function (e) {
            e.preventDefault();
            $('html').removeAttr('style').removeClass('sr-grayscale sr-links-underline sr-readable-font');
        });
    }
}